import { inject } from '@angular/core';
import { signalStoreFeature, withMethods } from '@ngrx/signals';
import { MessageService } from 'primeng/api';

export const withMessage = () => {
  return signalStoreFeature(
    withMethods((store) => {
      const messageService = inject(MessageService);
      return {
        showSuccess: (boxKey: string, message: string) => {
          messageService.add({
            key: boxKey,
            severity: 'success',
            detail: message,
            life: 3000,
          });
        },
        showErr: (boxKey: string, message: string) => {
          messageService.add({
            key: boxKey,
            severity: 'error',
            detail: message,
            life: 5000,
          });
        },
        showWarning: (boxKey: string, message: string) => {
          messageService.add({
            key: boxKey,
            severity: 'warn',
            detail: message,
            life: 3000,
          });
        },
      };
    })
  );
};
