import { Type, computed, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  patchState,
  signalStoreFeature,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, catchError, map, pipe, switchMap, tap } from 'rxjs';
import { SearchInput, SearchService } from '../service/search.service';
import { withMessage } from './withMessage';
import { withStorageSync } from './withStorageSync';

export type PaginationState<T, F> = {
  paginatedData: T[];
  paginatedDataAbp: any;
  filters: Partial<F>;
  first: number;
  page: number | null;
  size: number | null;
  sortBy: string | null;
  sortDirection?: 'ASC' | 'DESC';
  totalRecords: number;
  paginationLoading: boolean;
  sizeOptions: number[];
  key?: string;
};

//TODO: persistent filter and pagination data
export function withPagination<TEntity, TFilter>(
  loader: Type<SearchService<TEntity, TFilter>>,
  key: string
) {
  return signalStoreFeature(
    withMessage(),
    withState<PaginationState<TEntity, TFilter>>(() => ({
      filters: {},
      page: null,
      size: null,
      first: 0,
      paginatedData: [],
      paginatedDataAbp: [],
      sortBy: '',
      sortDirection: 'ASC',
      totalRecords: 0,
      paginationLoading: false,
      sizeOptions: [25, 50, 100],
      key: key,
    })),
    withStorageSync({
      key: key || 'data',
      autoSync: true,
      storage: () => sessionStorage,
    }),
    withComputed((store) => ({
      offset: computed(() => ((store.page() || 1) - 1) * <number>store.size()),
      canBeLoadedMore: computed(
        () => store.totalRecords() > <number>store.size()
      ),
    })),
    withMethods((store, service = inject(loader)) => ({
      initPagination: rxMethod<
        SearchInput<Partial<TFilter>> & {
          successCallback?: (...args: any[]) => void;
        }
      >(
        pipe(
          tap(() =>
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }))
          ),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  paginationLoading: false,
                  page: input.page || value.page,
                  size: input.size || value.size,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                  paginatedDataAbp: res?.data?.data[0],
                  filters:
                    Object.keys(store.filters()).length === 0
                      ? input.filter
                      : store.filters(),
                  sortBy: input.sortBy,
                  sortDirection: input.sortDirection || value.sortDirection,
                }));
                input.successCallback
                  ? input.successCallback(res.data?.data)
                  : null;
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),
      filter: rxMethod<{ filter: Partial<TFilter> }>(
        pipe(
          tap(() =>
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }))
          ),
          map((input): SearchInput<Partial<TFilter>> => {
            return {
              page: store.page() || undefined,
              size: store.size() || undefined,
              filter: input.filter,
              sortBy: store.sortBy() || undefined,
              sortDirection: store.sortDirection
                ? store.sortDirection()
                : undefined,
            };
          }),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                  filters: input.filter || {},
                }));
              }),
              catchError((err) => {
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),
      paginate: rxMethod<{ page: number; size: number }>(
        pipe(
          tap(() =>
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }))
          ),
          map((input): SearchInput<Partial<TFilter>> => {
            return {
              page: input.page,
              size: input.size,
              filter: store.filters(),
              sortBy: store.sortBy() || '',
              sortDirection: store.sortDirection
                ? store.sortDirection()
                : undefined,
            };
          }),
          switchMap((input) =>
            service.search(input).pipe(
              tap(() =>
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: true,
                }))
              ),
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  page: input.page,
                  size: input.size,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                }));
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),
      sort: rxMethod<{ sortBy: string; order: number }>(
        pipe(
          tap(() =>
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }))
          ),
          map((input): SearchInput<Partial<TFilter>> => {
            return {
              // page: store.page ? store.page() : undefined,
              // size: store.size ? store.size() : undefined,
              page: store.page() || undefined,
              size: store.size() || undefined,
              filter: store.filters(),
              sortBy: input.sortBy,
              sortDirection: input.order === 1 ? 'ASC' : 'DESC',
            };
          }),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                  sortBy: input.sortBy,
                  sortDirection: input.sortDirection,
                }));
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),

      filterReset: rxMethod<{ filter: Partial<TFilter> }>(
        pipe(
          tap(() =>
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }))
          ),
          map((input): SearchInput<Partial<TFilter>> => {
            // console.log('filter :: ', input);
            return {
              page: 1,
              size: 25,
              filter: {},
              sortBy: 'createdAt',
              sortDirection: 'DESC',
            };
          }),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                  filters: input.filter || {},
                  sortBy: 'createdAt',
                  sortDirection: input.sortDirection,
                }));
              }),
              catchError((err) => {
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),

      reset: rxMethod<SearchInput<Partial<TFilter>>>(
        pipe(
          tap((input) => {
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }));
          }),
          map((input): SearchInput<Partial<TFilter>> => {
            console.log('filter :: ', input);
            return {
              page: 1,
              size: 25,
              filter: {},
              sortBy: 'createdAt',
              sortDirection: 'DESC',
            };
          }),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  // page: 1,
                  // size: input.size,
                  // filters: {},
                  // sortBy: input.sortBy,
                  // sortDirection: input.sortDirection,
                  // paginationLoading: false,
                  // totalRecords: res.data?.totalRecords,
                  // paginatedData: res.data?.data,

                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                  filters: input.filter || {},
                }));
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),

      refresh: rxMethod<{}>(
        pipe(
          tap((input) => {
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }));
          }),
          map(
            (_) =>
              <SearchInput<Partial<TFilter>>>{
                page: store.page(),
                size: store.size(),
                filter: store.filters(),
                sortBy: store.sortBy(),
                sortDirection: store.sortDirection
                  ? store.sortDirection()
                  : 'ASC',
              }
          ),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  page: input.page,
                  size: input.size,
                  filters: input.filter,
                  sortBy: input.sortBy ? input.sortBy : value.sortBy,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                }));
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),

      loadMore: rxMethod<{}>(
        pipe(
          tap((input) => {
            patchState(store, (value) => ({
              ...value,
              paginationLoading: true,
            }));
          }),
          map(
            (_) =>
              <SearchInput<Partial<TFilter>>>{
                page: store.page(),
                size: 2 * (store.size() || 0),
                filter: store.filters(),
                sortBy: store.sortBy(),
                sortDirection: store.sortDirection
                  ? store.sortDirection()
                  : 'ASC',
              }
          ),
          switchMap((input) =>
            service.search(input).pipe(
              map((res) => {
                patchState(store, (value) => ({
                  ...value,
                  page: input.page,
                  size: input.size,
                  filters: input.filter,
                  sortBy: input.sortBy ? input.sortBy : value.sortBy,
                  paginationLoading: false,
                  totalRecords: res.data?.totalRecords,
                  paginatedData: res.data?.data,
                }));
              }),
              catchError((err) => {
                patchState(store, (value) => ({
                  ...value,
                  paginationLoading: false,
                }));
                if (err.error?.message)
                  store.showErr('root-msgs', err.error?.message);
                return EMPTY;
              })
            )
          )
        )
      ),
    })),
    withHooks({
      onInit: (store, router = inject(Router)) => {
        router.events
          .pipe(
            tap((event) => {
              if (event instanceof NavigationStart) {
                const url = event.url;
                // console.log(url, `${key}`);
                // if(`${key}` === 'hoto'){
                //   sessionStorage.removeItem('leads');
                //   sessionStorage.removeItem('client');
                //   sessionStorage.removeItem('roles');
                // }
                // console.log(url.includes(`/${key}`));

                if (!url.includes(`/${key}`) || url.includes('roles')) {
                  store.clearStorage();
                }
              }
            })
          )
          .subscribe();
      },
    })
  );
}
